<template>
  <div class="card">
    <div class="card-body">
      <a href="#" class="btn btn-primary btn-xs float-right" @click.prevent="show_add_contact = true"><i class="lni-plus mr-2"></i>Add Contacts</a>
      <a href="#" class="btn btn-success btn-xs float-right mr-2" @click.prevent="upload_excel = true"><i class="lni-files mr-2"></i>Import from Excel</a>
      <h4 class="card-title">{{ list.name }}</h4>
      <p>{{ list.description == 'null' ? '' : list.description }}</p>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in list.contacts" :key="`contact-${i}`">
            <td>{{ i+1 }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.phone_number }}</td>
            <td>
              <a href="#" class="btn btn-danger btn-xs" @click.prevent="remove(user)"><i class="lni-trash"></i></a>
            </td>
          </tr>
          <tr v-if="list.contacts.length == 0">
            <td colspan="4">No contacts to display</td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal :show="show_add_contact" @close="show_add_contact = false">
      <template slot="header">Add Contacts</template>
      <form @submit.prevent="create">
        <div class="form-group">
          <label>Name</label>
          <input type="text" placeholder="Name" v-model="contact.name" class="form-control">
        </div>
        <div class="form-group">
          <label>Phone Number</label>
          <input type="text" placeholder="Phone Number" v-model="contact.phone_number" class="form-control">
        </div>
        <div class="form-group text-right">
          <button class="btn btn-primary"><i class="lni-plus mr-2"></i>Save</button>
        </div>
      </form>
    </modal>
    <modal :show="upload_excel" @close="upload_excel = false">
      <template slot="header">Import Contacts</template>
      <div class="alert alert-info" v-if="the_error.length == 0">Your excel file must contain a number column and an optional name column</div>
      <div class="alert alert-danger" v-if="the_error.length > 0">{{ the_error }}</div>
      <form @submit.prevent="importFile">
        <div class="form-group">
          <label>Select File</label>
          <input type="file" ref="import_file" class="form-control" placeholder="Select File" required>
        </div>
        <div class="form-group text-right">
          <button class="btn btn-primary"><i class="lni-upload mr-2"></i>Upload</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      list: { contacts: [] },
      show_add_contact: false,
      contact: {},
      upload_excel: false,
      the_error: ''
    }
  },

  methods: {
    fetch() {
      this.$loader.start('main-content')
      this.$axios.get(`/api/v1/apps/${this.$route.params.id}/contact-lists/${this.$route.params.list_id}`).then(response => {
        this.list = response.data
        this.$loader.stop()
      })
    },

    create() {
      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/contact-lists/${this.$route.params.list_id}`, this.contact).then(response => {
        this.$loader.stop()
        this.list.contacts = response.data
        this.show_add_contact = false
      })
    },

    remove(contact) {
      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/contact-lists/${this.$route.params.list_id}/${contact.id}`, this.contact).then(response => {
        this.$loader.stop()
        this.list.contacts = response.data
      })
    },

    importFile() {
      var data = new FormData()
      data.append("excel", this.$refs.import_file.files[0])

      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/contact-lists/${this.$route.params.list_id}/import`, data, {
        headers: { "content-type": "multipart/form-data" },
      }).then(response => {
        this.$loader.stop()
        this.list.contacts = response.data
        this.upload_excel = false
      }).catch(error => {
        if(error.response) {
          if(error.response.status == 422) {
            this.the_error = error.response.data.message
          }
        }
      })
    }
  }
}
</script>